var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"新增","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                { required: true, message: '请输入用户名' },
                { max: 32, message: '超出最大长度(32)' },
              ],
            },
          ]),expression:"[\n            'username',\n            {\n              rules: [\n                { required: true, message: '请输入用户名' },\n                { max: 32, message: '超出最大长度(32)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                { required: true, message: '请输入名称' },
                { max: 64, message: '超出最大长度(64)' },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                { required: true, message: '请输入名称' },\n                { max: 64, message: '超出最大长度(64)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"角色"}},[_c('role-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['role']),expression:"['role']"}]})],1),_c('a-form-item',{attrs:{"label":"分拣规则"}},[_c('SortingRuleSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sorting_rule']),expression:"['sorting_rule']"}]})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]),expression:"['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]"}],attrs:{"allowClear":true}})],1)],1),_c('div',{staticStyle:{"color":"rgb(255, 77, 79)","text-align":"center"}},[_vm._v("默认初始密码为: 123456, 登录后请修改密码")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }