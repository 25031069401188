<template>
  <div>
    <a-modal
      v-model="visible"
      title="新增"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="用户名">
          <a-input
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: '请输入用户名' },
                  { max: 32, message: '超出最大长度(32)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  { required: true, message: '请输入名称' },
                  { max: 64, message: '超出最大长度(64)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="角色">
          <role-select v-decorator="['role']" />
        </a-form-item>
        <a-form-item label="分拣规则">
          <SortingRuleSelect v-decorator="['sorting_rule']" />
        </a-form-item>
        <a-form-item label="备注">
          <a-input v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]" :allowClear="true" />
        </a-form-item>
      </a-form>

      <div style="color: rgb(255, 77, 79); text-align: center">默认初始密码为: 123456, 登录后请修改密码</div>
    </a-modal>
  </div>
</template>

<script>
import { userCreate } from "@/apis/system";

export default {
  components: {
    RoleSelect: () => import("@/components/RoleSelect"),
    SortingRuleSelect: () => import("@/components/SortingRuleSelect"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          userCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .catch((error) => {
              if (error.non_field_errors && error.non_field_errors.length > 0) {
                this.$message.error(error.non_field_errors[0]);
              } else {
                const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                  acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                  return acc;
                }, {});
                this.dataForm.setFields(errorFields);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
